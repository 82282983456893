import * as qs from "querystring";
import React from "react";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router";
import {Button, Col, Collapse, FormGroup, Input, Label} from "reactstrap";
import './TableFilters.css';

import {Card} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import {MdClear, MdSearch} from "react-icons/md";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {generateUrlForWorkspace} from "./common";


const ButtonContainer = styled.div`

    .btn {
        margin-right: 1em;
        margin-bottom: 1em;
    }

    .icon-container {
        display: flex;
        vertical-align: middle
    }
`;


class TableFilters extends React.Component {


    constructor(props) {
        super(props);

        const curQueryString = qs.parse(this.props.location.search.replace(/^\?/, ''));

        const diffs = curQueryString.diffs || "";
        const httpStatus = curQueryString.httpStatus || "";
        const isRegexFilter = curQueryString.uriSearch?.includes("isRegex:true") || false;

        this.state = {
            isRegexFilter: isRegexFilter,
            uriSearch: curQueryString.uriSearch || "",
            titleChecked: diffs.includes("Title"),
            metaChecked: diffs.includes("Meta"),
            h1Checked: diffs.includes("H1"),
            h2Checked: diffs.includes("H2"),
            redirectChecked: diffs.includes("Redirect"),
            status200Checked: httpStatus.includes("200"),
            status301Checked: httpStatus.includes("301"),
            status3xxChecked: httpStatus.includes("302"),
            status4xxChecked: httpStatus.includes("404"),
            status5xxChecked: httpStatus.includes("500"),
            collapse: curQueryString.diffs !== undefined || curQueryString.uriSearch !== undefined
        };

    }


    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.updateFilters();
        }
    }


    updateFilters() {
        const curQueryString = this.parseQuerystring();

        const diffs = curQueryString.diffs || "";
        const httpStatus = curQueryString.httpStatus || "";
        const isRegexFilter = curQueryString.uriSearch?.includes("isRegex:true") || false;

        this.setState({
            isRegexFilter: isRegexFilter,
            uriSearch: this.getUriSearch(curQueryString) || "",
            titleChecked: diffs.includes("Title"),
            metaChecked: diffs.includes("Meta"),
            h1Checked: diffs.includes("H1"),
            h2Checked: diffs.includes("H2"),
            redirectChecked: diffs.includes("Redirect"),
            status200Checked: httpStatus.includes("200"),
            status301Checked: httpStatus.includes("301"),
            status3xxChecked: httpStatus.includes("302"),
            status4xxChecked: httpStatus.includes("404"),
            status5xxChecked: httpStatus.includes("500"),
        });
    }

    getUriSearch(curQueryString) {
        const queryMatch = curQueryString.uriSearch?.match(/query:([^|]+)/);
        return queryMatch ? queryMatch[1] : "";
    }

    parseQuerystring() {
        return qs.parse(this.props.location.search.replace(/^\?/, ''));
    }

    handleChange = ({target}) => {
        this.setState({
            [target.name]: target.value
        });
    };

    handleKeyPress = (target) => {
        if (target.charCode === 13) {
            this.onApply(target);
        }
    };

    onApply = (e) => {
        e.preventDefault();
        this.setUri();
    };


    setUri = () => {
        let filters = "?";

        filters += this.buildUriSearchParameter();

        filters += "&diffs=";
        filters += (this.state.titleChecked ? "Title," : "");
        filters += (this.state.h1Checked ? "H1," : "");
        filters += (this.state.metaChecked ? "Meta," : "");
        filters += (this.state.redirectChecked ? "Redirect" : "");


        filters += "&httpStatus=";

        filters += (this.state.status200Checked ? "200," : "");
        filters += (this.state.status301Checked ? "301," : "");
        filters += (this.state.status3xxChecked ? "300,302,303,304,305,307,307," : "");
        filters += (this.state.status4xxChecked ? "400,401,403,404," : "");
        filters += (this.state.status5xxChecked ? "500,501,503,504,505," : "");

        const resolvedURL = this.resolvePath(this.props.history.location.pathname, filters);
        this.props.history.push(resolvedURL);
    };

    buildUriSearchParameter() {
        let uriSearch = "uriSearch=";
        if (this.state.isRegexFilter) {
            uriSearch += this.state.isRegexFilter ? "isRegex:true|" : "";
        }
        uriSearch += "query:" + encodeURIComponent(this.state.uriSearch);
        return uriSearch;
    }

    handleSwitchToggle = name => event => {
        this.setState({...this.state, [name]: event.target.checked}, this.setUri);
    };

    toggleCollapse = () => {
        this.setState((prev) => {
            return {collapse: !prev.collapse}
        });
    };

    resolvePath(from, to) {
        const resolvedURL = new URL(to, 'http://dummyhost' + from).href;
        return resolvedURL.replace('http://dummyhost', '');
        ;
    }

    render() {

        let clearButton = null;

        if (this.hasFiltersInQuery()) {
            const dashboardUrl = generateUrlForWorkspace(this.props.workspaces.current.seqNumber);
            clearButton = <ButtonWithIcon icon={MdClear} tag={Link} to={dashboardUrl}>Clear Filters</ButtonWithIcon>;
        }


        return (<div>


            <ButtonContainer>
                <ButtonWithIcon icon={MdSearch} onClick={this.toggleCollapse} id="toggler">Advanced
                    Search</ButtonWithIcon>
                {clearButton}
            </ButtonContainer>


            <Collapse isOpen={this.state.collapse}>
                <div className={"filterBox"}>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card className="p-3">
                                <FormGroup check>
                                    <div className="d-flex border rounded"
                                         style={{background: "#f8f9fa", marginLeft: "-1.25rem"}}>
                                        <Input
                                            id="uriSearch"
                                            name="uriSearch"
                                            value={this.state.uriSearch}
                                            onChange={this.handleChange}
                                            onKeyPress={this.handleKeyPress}
                                            placeholder="Search URL..."
                                            className="border-0"
                                        />
                                        <Button onClick={this.onApply} color="link">
                                            <span className="ml-1">Search</span>
                                        </Button>
                                    </div>
                                </FormGroup>
                                <FormGroup check className="mt-3 ml-1">
                                    <Input
                                        type="checkbox"
                                        id="regexFilter"
                                        checked={this.state.isRegexFilter}
                                        onChange={(e) => {
                                            this.setState({isRegexFilter: e.target.checked}, () => {
                                                if (this.state.uriSearch.length > 0) {
                                                    this.setUri();
                                                }

                                            });
                                        }}
                                    />
                                    <Label for="regexFilter" className="mt-1">Search with Regex</Label>
                                </FormGroup>
                            </Card>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <Card>
                                <p/>
                                <MetaFilter name={"Title not implemented"} checked={this.state.titleChecked}
                                            onToggle={this.handleSwitchToggle("titleChecked")}/>
                                <MetaFilter name={"H1 not implemented"} checked={this.state.h1Checked}
                                            onToggle={this.handleSwitchToggle("h1Checked")}/>
                                <MetaFilter name={"Meta Description not impl"} checked={this.state.metaChecked}
                                            onToggle={this.handleSwitchToggle("metaChecked")}/>
                                <MetaFilter name={"Redirect not implemented"} checked={this.state.redirectChecked}
                                            onToggle={this.handleSwitchToggle("redirectChecked")}/>
                            </Card>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <Card>
                                <p/>
                                <MetaFilter name={"Status code 200"} checked={this.state.status200Checked}
                                            onToggle={this.handleSwitchToggle("status200Checked")}/>
                                <MetaFilter name={"Status code 301"} checked={this.state.status301Checked}
                                            onToggle={this.handleSwitchToggle("status301Checked")}/>
                                <MetaFilter name={"Status code 3xx"} checked={this.state.status3xxChecked}
                                            onToggle={this.handleSwitchToggle("status3xxChecked")}/>
                                <MetaFilter name={"Status code 4xx"} checked={this.state.status4xxChecked}
                                            onToggle={this.handleSwitchToggle("status4xxChecked")}/>
                                <MetaFilter name={"Status code 5xx"} checked={this.state.status5xxChecked}
                                            onToggle={this.handleSwitchToggle("status5xxChecked")}/>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </Collapse>
        </div>);
    }

    hasFiltersInQuery() {

        const curQueryString = this.parseQuerystring();
        const parameters = Object.keys(curQueryString);


        for (let curParam of parameters) {
            if (curParam === "page") continue;

            if (curQueryString[curParam].length > 0) return true;
        }

        return false;

    }
}


class ButtonWithIcon extends React.Component {


    render() {

        let {tag, to, onClick, id} = this.props;


        return (
            <Button id={id} onClick={onClick} tag={tag} to={to} color="secondary" size="sm">
                <div className="icon-container">
                    {this.getIcon()}&nbsp; {this.props.children}
                </div>
            </Button>
        )
    };

    getIcon() {
        if (this.props.icon === undefined) return null;
        let Tag = this.props.icon;
        return <Tag size="1.5em"/>;
    }
}


class MetaFilter extends React.Component {
    render() {
        return (
            <FormGroup className={"v-center"}>
                <Label sm={9}>{this.props.name}</Label>
                <Col sm={3} className={"v-center"}>
                    <Switch onChange={this.props.onToggle} checked={this.props.checked} id="normal-switch"/>
                </Col>
            </FormGroup>);
    }
}


function mapsStateToProps(state) {
    return {...state.monitoredUri, "workspaces": state.workspaces};
}

export default withRouter(connect(mapsStateToProps)(TableFilters));
