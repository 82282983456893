import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {Col, FormGroup, Row} from 'reactstrap';
import {generateUrlForWorkspace} from '../common';
import Fuse from 'fuse.js';
import apiFetch from '../../util/fetch-utils';
import {toaster} from 'evergreen-ui';
import {WORKSPACE_CREATED_EVENT} from '../../../actions/workspaces';
import {CountryFlag} from '../CountryFlag';
import styled from 'styled-components';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Avatar,
    Container,
    Grid,
    Typography,
    Paper,
    InputBase,
    Button,
    IconButton,
} from '@material-ui/core';
import {AddCircle} from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import WorkspaceCard from "./WorkspaceCard";

const MAX_WORKSPACE_PER_PAGE = 16;

const SearchContainer = styled(Paper)`
    padding: 2px 4px;
    display: flex;
    align-items: center;
`;

const StyledInput = styled(InputBase)`
    margin-left: 1rem;
    flex: 1;
`;


const WorkspaceSelectionPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const workspaces = useSelector(state => state.workspaces);
    const [filter, setFilter] = useState('');

    const createWorkspace = () => {
        apiFetch('/api/workspaces', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => {
            if (response.status === 201) {
                response.json().then((responseJson) => {
                    dispatch({type: WORKSPACE_CREATED_EVENT, payload: responseJson});
                    history.push(generateUrlForWorkspace(responseJson.seqNumber) + '/admin');
                });
            } else {
                toaster.danger('Unable to create workspace.', {
                    description: response.status + ' ' + response.statusText,
                    duration: 10,
                });
            }
        });
    };

    const handleChange = (event) => {
        setFilter(event.target.value);
    };

    if (!workspaces.fetched) return <p>Fetching Workspaces...</p>;

    let filteredWorkspaces = workspaces.data;
    if (filter.length > 0) {
        const options = {
            shouldSort: true,
            tokenize: true,
            includeScore: true,
            threshold: 0.2,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 0,
            keys: ['name'],
        };
        const fuse = new Fuse(workspaces.data, options);
        const result = fuse.search(filter);
        filteredWorkspaces = result.map((element) => element.item);
    }

    return (
        <div>
            <Container>
                <h4>Select workspace</h4>
                <FormGroup>
                    <Row className="toolbar-container">
                        <Col md={9}>
                            <SearchContainer>
                                <StyledInput
                                    type="search"
                                    name="filter"
                                    placeholder="Search workspace"
                                    value={filter}
                                    onChange={handleChange}
                                    inputProps={{'aria-label': 'search workspaces'}}
                                />
                                <IconButton aria-label="search">
                                    <SearchIcon/>
                                </IconButton>
                            </SearchContainer>
                        </Col>
                        <Col md={3}>
                            <Button variant="contained" color="primary" onClick={createWorkspace}>
                                <AddCircle/>&nbsp;Add Workspace
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>
                <Typography variant="body2" color="textSecondary" component="p">
                    Showing <b>{Math.min(MAX_WORKSPACE_PER_PAGE, workspaces.data.length)}</b> of{' '}
                    <b>{workspaces.data.length}</b> workspaces.
                </Typography>
                <br/>
            </Container>
            <Container>
                <Grid container spacing={4}>
                    {filteredWorkspaces.slice(0, MAX_WORKSPACE_PER_PAGE).map((curWs, index) => (
                        <WorkspaceCard key={index} workspace={curWs}/>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

export default WorkspaceSelectionPage;
