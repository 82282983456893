import React from 'react';
import {Card, CardHeader, CardContent, CardActions, Grid, Avatar, Button} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {generateUrlForWorkspace} from '../common';
import styled from "styled-components";
import {CountryFlag} from "../CountryFlag";

const LightAvatar = styled(Avatar)`
    background-color: #f1eded !important;
`;
const LargeButton = styled(Button)`
    margin-left: 5% !important;
    margin-bottom: 10px !important;
    width: 90%;
`;
const WorkspaceCard = ({workspace, key}) => {
    return (
        <Grid key={key} item xs={12} sm={4} md={3}>
            <Card>
                <CardHeader
                    title={workspace.name?.substring(0, 22) || "Unnamed Workspace"}
                    subheader={workspace.websiteUrl || "No Website Available"}
                    avatar={
                        <LightAvatar>
                            {workspace.countryCode ? (
                                <CountryFlag countryCode={workspace.countryCode}/>
                            ) : (
                                <span>No Flag</span>
                            )}
                        </LightAvatar>
                    }
                />
                <CardContent/>
                <CardActions>
                    <LargeButton
                        component={Link}
                        variant="contained"
                        to={generateUrlForWorkspace(workspace.seqNumber)}>
                        Open
                    </LargeButton>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default WorkspaceCard;