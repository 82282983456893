import React, {useEffect, useState} from "react";
import {Col, FormGroup, Input, Table} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import "./style.css";
import apiFetch from "../util/fetch-utils";
import {
    CURRENT_MONITORED_SELECT_SNAPSHOT,
    CURRENT_MONITORED_URI_INBOUND_LINKS_FETCHED
} from "../../reducers/currentMonitoredUri";
import {MdLink} from "react-icons/md";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import {formatDate} from "./commons";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {selectLastCrawlId} from '../../selectors/crawlIdSelector';

const MAX_LINKS_PER_PAGE = 10;
const searchPlaceholder = "search url";

function InboundLinks({uri, isVisible}) {
    const dispatch = useDispatch();

    const websiteCrawlId = useSelector(selectLastCrawlId);

    const inboundLinks = useSelector((state) => state.currentMonitoredUri.inboundLinks);
    const lastSnapshotDate = useSelector((state) => state.currentMonitoredUri.lastSnapshotDate);
    const curSnapshotDate = useSelector((state) => state.currentMonitoredUri.curSnapshotDate);

    const [searchInboundLinks, setSearchInboundLinks] = useState(inboundLinks.curUrlRegex || "");

    const handleChange = (event) => {
        setSearchInboundLinks(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.charCode === 13) {
            onApply(event);
        }
    };

    const onApply = (event) => {
        event.preventDefault();
        fetchInboundLinks(uri);
    };

    useEffect(() => {
        if (isVisible && uri && uri !== inboundLinks.curUri) {
            fetchInboundLinks(uri);
        }
    }, [isVisible, uri, inboundLinks.curUri]);

    const fetchInboundLinks = (uri) => {
        if (!websiteCrawlId) return;

        let filterQueryString = searchInboundLinks ? `&urlRegex=.*${searchInboundLinks}.*` : "";

        apiFetch(`/archive-api/page-links/inbound?uri=${encodeURIComponent(uri)}&websiteCrawlId=${websiteCrawlId}${filterQueryString}`)
            .then(response => response.json())
            .then(responseJson => {
                dispatch({
                    type: CURRENT_MONITORED_URI_INBOUND_LINKS_FETCHED,
                    payload: {
                        inboundLinks: {
                            ...responseJson,
                            curUri: uri,
                            curUrlRegex: searchInboundLinks
                        },
                    }
                });
            });
    };

    const selectLastCrawl = () => {
        dispatch({type: CURRENT_MONITORED_SELECT_SNAPSHOT, curSnapshotDate: lastSnapshotDate});
    };

    const isLastSnapshotSelected = curSnapshotDate && curSnapshotDate === lastSnapshotDate;

    if (!isLastSnapshotSelected) {
        return <NotLastSnapshotFallback selectLastCrawl={selectLastCrawl} curSnapshotDate={curSnapshotDate}
                                        lastSnapshotDate={lastSnapshotDate}
                                        inboundLinksCount={inboundLinks.totalElements}/>;
    }

    return (
        <Container>
            <FormGroup row>
                <Col sm={10}>
                    <Input name="searchInboundLinks" id="searchInboundLinks" placeholder={searchPlaceholder}
                           value={searchInboundLinks}
                           onChange={handleChange}
                           onKeyPress={handleKeyPress}/>
                </Col>

                <Button color="primary" className={"align-right-in-row"} onClick={onApply}>Search</Button>
            </FormGroup>

            <Typography variant="body2" color="textSecondary" component="p">
                Showing <b>{Math.min(MAX_LINKS_PER_PAGE, inboundLinks.content.length)}</b> of <b>{inboundLinks.totalElements}</b> Inbound
                links. Use '{searchPlaceholder}' to refine.
            </Typography>
            <br/>
            <InboundLinksTable inboundLinks={inboundLinks}/>
        </Container>
    );
}

const InboundLinksTable = ({inboundLinks}) => {
    return (
        <Table size="sm" borderless striped hover>
            <thead>
            <tr>
                <th style={{width: "6em"}}>Source</th>
                <th>Url</th>
            </tr>
            </thead>
            <tbody>
            {inboundLinks.content.map((row, index) => (
                <tr key={index}>
                    <td>AHREF</td>
                    <td>{row} &nbsp;<a href={row} target="_blank" rel="noopener noreferrer"><MdLink/></a></td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

const NotLastSnapshotFallback = ({selectLastCrawl, curSnapshotDate, lastSnapshotDate, inboundLinksCount}) => {
    const additionalInfo = !Number.isInteger(inboundLinksCount)
        ? <span>. Unfortunately, we don't have a record of how many inbound links this page had </span>
        : <span> but we know that this page had <b>{inboundLinksCount}</b> inbound links </span>;

    return (
        <Container>
            <Card>
                <CardHeader title="Inbound links list is only available for the last crawl"/>
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        We don't store the inbound links for previous crawls{additionalInfo} when we crawled it, on
                        the <b>{formatDate(curSnapshotDate)}</b>
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={selectLastCrawl} size="small" color="primary">
                        Go to last crawl
                    </Button>
                </CardActions>
            </Card>
        </Container>
    );
};

const Container = styled.div`
    padding: 3em 1em 1em;
`;

export default InboundLinks;
