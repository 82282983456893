import {CardContent} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {Container} from "reactstrap";
import styled from "styled-components";
import {Row} from "reactstrap";
import Typography from "@material-ui/core/Typography";

export const DashboardWidgetCard = styled(Card)`
    margin: 1em 0;
    min-height: 18em;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: #ffffff;
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    }

    .MuiCardHeader-root {
        font-size: 0.55rem;
        font-weight: 400;
        background-color: #f5f5f5;
        border-bottom: 1px solid #e0e0e0;
        padding: 4px 10px;
    }

    .MuiTypography-h5 {
        font-size: 1.1rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.334;
        letter-spacing: 0em;
    }

    .MuiCardContent-root:last-child {
        padding-bottom: 16px;
    }
`;

export const DashboardTitle = styled.h2`
    font-size: 0.875rem; 
    font-weight: 600;
    color: gray;
    text-transform: uppercase; 
    line-height: 1.5;
    margin: 0;
    padding: 0.5em 1em;
    background: #f7f8fa;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    & .workspace-name,
    & .workspace-url {
        text-transform: none; 
    }
`;

export const DashboardContainer = styled(Container)`
    @media (max-width: 768px) {
        padding: 1em;
    }
`;

export const FlexCardContent = styled(CardContent)`
    flex: 1;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
`;

export const DashboardRow = styled(Row)`
    display: flex;

    > div {
        display: flex;
        flex-direction: column;
    }

    > div > div { 
        flex: 1;
        display: flex;
        flex-direction: column;
    }
`;

export const WorkspaceTitle = styled.div`
    display: flex;
    align-items: center; 
    gap: 8px;
`;

export const CountryFlagWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem; 
    line-height: 1; 
`;

export const WorkspaceNameWrapper = styled.div`
    display: flex;
    align-items: center;
    color: #000;
    font-size: 2.0rem;
    line-height: 1.5;
    text-transform: none;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
`;

export const WorkspaceUrlWrapper = styled.div`
  margin-top: 8px;
  font-size: 1rem;
  display: inline-block;
`;

export const WorkspaceUrl = ({url}) => {
    if (!url) return null;

    return (
        <Typography
            variant="body2"
            style={{
                color: "#888888",
                fontSize: "0.875rem",
                marginTop: "0.2em",
                textTransform: "none",
                wordBreak: "break-word",
            }}
        >
            {url}
        </Typography>
    );
};
