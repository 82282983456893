import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import connect from "react-redux/es/connect/connect";
import { fieldEditBegin } from "../RecommendationEditor/redux/actions";
import { COMPLETED, ERROR_404, IN_PROGRESS } from "../../reducers/currentMonitoredUri";
import { extractRedirectChain } from "./commons";
import { PageSnapshotField, PageSnapshotListField } from "./PageSnapshotField";
import Table from "@material-ui/core/Table";


class CurrentMonitoredUriDetails extends React.Component {



    beginEdit = (isArrayField) => (fieldId) => {
        let row = this.props;

        this.props.fieldEditBegin(
            row.id,
            row.uri, fieldId, isArrayField,
            row.currentValue[fieldId],
            row.recommendation[fieldId]
        );

    };

    beginEditDestinationUri = (fieldId) => {
        let row = this.props;

        this.props.fieldEditBegin(
            row.id,
            row.uri, fieldId, false,
            row.currentValue.destinationUri,
            row.recommendation[fieldId]
        );
    };


    render() {

        let { cur, ref } = this.props.values;

        Object.assign(cur, extractRedirectChainValuesForView(cur));

        const destinationUriFieldValue = cur.destinationUri + (cur.redirectChain !== null ? " (" + cur.redirectChain + ")" : "");
        const safeSitemapLinks = cur?.lastCrawl?.inboundLinks?.internal?.SITEMAP || [];        

        return (
            <LoadingOverlay active={cur.fetchStatus !== COMPLETED}
                spinner={cur.fetchStatus === IN_PROGRESS}
                text={this.getOverlayText(cur)}>
                <Table style={{ width: "auto" }}>
                    <tbody>
                        <PageSnapshotField beginEdit={this.beginEditDestinationUri} fieldName="Destination URL" fieldId="destinationUri" fieldValueCur={cur.destinationUri}
                            fieldValueRef={ref.destinationUri} displayedText={destinationUriFieldValue} />
                        <PageSnapshotField beginEdit={this.beginEdit(false)} fieldName="Title" fieldId="title" fieldValueCur={cur.title} fieldValueRef={ref.title} />
                        <PageSnapshotListField beginEdit={this.beginEdit(true)} fieldName="Meta Description" fieldId="metaDescriptions" fieldValueCur={cur.metaDescriptions}
                            fieldValueRef={ref.metaDescriptions} />
                        <PageSnapshotListField beginEdit={this.beginEdit(true)} fieldName="H1s" fieldId="h1s" fieldValueCur={cur.h1s} fieldValueRef={ref.h1s} />
                        <PageSnapshotListField diffDisabled fieldName="H2s" fieldId="h2s" fieldValueCur={cur.h2s} fieldValueRef={ref.h2s} />
                        <PageSnapshotListField diffDisabled fieldName="Canonicals" fieldId="canonicals" fieldValueCur={cur.canonicals} fieldValueRef={ref.canonicals} />
                        <PageSnapshotListField diffDisabled fieldName="Sitemap Links" fieldId="sitemaps" fieldValueCur={safeSitemapLinks} fieldValueRef={safeSitemapLinks} />
                        <PageSnapshotField diffDisabled fieldName="Crawl Status" fieldValueCur={cur.crawlStatus} />
                    </tbody>
                </Table>
            </LoadingOverlay>
        );
    }

    getOverlayText(cur) {

        switch (cur.fetchStatus) {
            case IN_PROGRESS:
                return "Loading...";
            case COMPLETED:
                return "";
            case ERROR_404:
                return 'Crawl not present for this day';

            default:
                return "Error";
        }
    }
}


function extractRedirectChainValuesForView(currentValue) {
    return {
        redirectChain: extractRedirectChain(currentValue)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        fieldEditBegin:
            (monitoredUriId, uri, fieldId, isArrayField, currentValue, recommendation) =>
                dispatch(fieldEditBegin(monitoredUriId, uri, fieldId, isArrayField, currentValue, recommendation))
    };
}

function mapsStateToProps(state) {
    return { ...state.currentMonitoredUri };
}

export default connect(mapsStateToProps, mapDispatchToProps)(CurrentMonitoredUriDetails);
