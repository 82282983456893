import React, { useState } from 'react';
import {
    Box,
    Button,
    Collapse,
    makeStyles,
    CircularProgress
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Status from './Status';
import Progress from './Progress';
import Controls from './Controls';
import CrawlDetails from './CrawlDetails';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    statusBox: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[100],
        border: `1px solid ${theme.palette.grey[300]}`,
        width: '100%',
        marginBottom: theme.spacing(0), // Default for mobile
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(1.5), // Increased for desktop
        },
    },
    progressBarControlsContainer: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            justifyContent: 'space-between',
        },
    },
    progressBar: {
        flexGrow: 1,
        marginRight: theme.spacing(2),
        minWidth: '6em',
        height: '1.5em',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(1),
        },
    },
    controlsWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
    },
    mobileFullWidthButton: {
        width: 'auto',
        padding: theme.spacing(0.5, 2), // Reduced padding to make the button less tall
        fontSize: '0.875rem', // Slightly smaller font size for balance
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
    },
    mobileSubtleButton: {
        color: theme.palette.grey[700], // Subtle text color
        borderColor: theme.palette.grey[300], // Light border for outlined button
        '&:hover': {
            backgroundColor: theme.palette.grey[100], // Light hover effect
        },
    },
}));

function CrawlerInfo({ lastCrawlId, onCrawlStart, crawlStarting }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const crawlStatus = useSelector((state) => state.crawls.crawls[lastCrawlId]);

    if (!crawlStatus || !crawlStatus.status) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    const isIdle = crawlStatus.status.pendingPages === 0 && crawlStatus.status.visitedPages === 0;
    const isCrawling = !isIdle && crawlStatus.status.pendingPages > 0;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
            <Box className={classes.statusBox}>
                <Status isCrawling={isCrawling} visitedPages={crawlStatus.status.visitedPages} />

                <Box className={classes.progressBarControlsContainer}>
                    <Box className={classes.progressBar}>
                        <Progress
                            progress={isIdle ? 100 :
                                (crawlStatus.status.visitedPages / (crawlStatus.status.visitedPages + crawlStatus.status.pendingPages)) * 100
                            }
                        />
                    </Box>

                    <Box className={classes.controlsWrapper}>
                        <Controls
                            isCrawling={isCrawling}
                            onCrawlStart={onCrawlStart}
                            crawlStarting={crawlStarting}
                        />
                    </Box>
                </Box>
            </Box>
            <Box textAlign="center">
                <Button
                    variant="outlined"
                    color="default"
                    onClick={handleExpandClick}
                    className={`${classes.mobileFullWidthButton} ${classes.mobileSubtleButton}`}
                >
                    {expanded ? 'Show Less' : 'Show More'}
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CrawlDetails crawlStatus={crawlStatus} />
            </Collapse>
        </div>
    );
}

export default CrawlerInfo;