import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const TableWrapper = styled.div`
    margin: 0.0em 0 0.3em 0;
    overflow-x: auto;
    border-radius: 8px;
    border: 1px solid #eee;
`;

const StyledTable = styled.table`
    width: 100%;
    text-align: center;
    th {
        background: #f0f3ff;
        color: #202e78;
        font-weight: 600;
        padding: 0.3em;
    }
    td {
        padding: 0.3em;
        border-bottom: 1px solid #eee;
    }
    tbody tr:nth-child(even) {
        background-color: #f7f7f7;
    }
`;

const StatusBadge = styled.span`
    display: inline-block;
    padding: 0.2em 0.6em;
    border-radius: 8px;
    font-weight: 600;
    color: #fff;
    background: ${({ color }) => color};
`;

const StatusCount = styled.span`
    color: ${({ color }) => color};
    font-weight: bold;
`;

export default function BoldHttpStatusTable({ stats, linkForStatusCodeFilter }) {
    const items = [
        { label: "200", count: stats.just200Count, color: "#187d06", filter: 200 },
        { label: "301", count: stats.http301Count, color: "#5c8bf2", filter: 301 },
        { label: "3xx", count: stats.http3xxCount, color: "#eba100", filter: "300,302,303,304,305,307,307" },
        { label: "4xx", count: stats.http4xxCount, color: "#fe000b", filter: "400,401,403,404" },
        { label: "5xx", count: stats.http5xxCount, color: "#fe000b", filter: "500,501,503,504,505" },
    ];

    return (
        <TableWrapper>
            <StyledTable>
                <thead>
                <tr>
                    <th>Status</th>
                    <th>Pages</th>
                </tr>
                </thead>
                <tbody>
                {items.map(({ label, count, color, filter }, i) => (
                    <tr key={i}>
                        <td>
                            <StatusBadge color={color}>{label}</StatusBadge>
                        </td>
                        <td>
                            <NavLink to={linkForStatusCodeFilter(filter)}>
                                <StatusCount color={color}>{count}</StatusCount>
                            </NavLink>
                        </td>
                    </tr>
                ))}
                </tbody>
            </StyledTable>
        </TableWrapper>
    );
}