import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';


import { HashRouter, Route } from 'react-router-dom';

import AddUrl from "./components/AddRecommendations";
import CurrentMonitoredUri from "./components/CurrentMonitoredUri";

import CssBaseline from "@material-ui/core/CssBaseline";
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { Container } from "reactstrap";
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { QueryParamProvider } from 'use-query-params'; 
import ErrorBoundary from "./ErrorBoundary";
import AccountDetails from "./components/AccountDetails";
import EmailVerifyView from "./components/Auth/EmailVerifyView";
import ForgottenPassword from "./components/Auth/ForgottenPassword";
import LoginView from "./components/Auth/LoginView";
import ResetPassword from "./components/Auth/ResetPassword";
import SignUpView from "./components/Auth/SignUpView";
import SomethingWentWrong from "./components/ErrorComponents/500";
import GoogleAnalyticsTag from "./components/GoogleAnalyticsTag";
import NavBar from "./components/NavBar";
import Checkout from "./components/Pricing/Checkout";
import Pricing from "./components/Pricing/Pricing";
import RecommendationEditor from "./components/RecommendationEditor/RecommendationEditor";
import Workspace from "./components/Workspace";
import SelectionListener from "./components/Workspace/SelectionListener";
import WorkspaceSelectionPage from "./components/Workspace/WorkspaceSelection/WorkspaceSelectionPage";
import WorkspaceAdmin from "./components/WorkspaceAdmin";
import AuthenticatedRoute from "./components/util/components/AuthenticatedRoute";
import './index.css';
import rootReducer from './reducers';

class App extends Component {


    render() {
        return (
            <HashRouter>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <GoogleAnalyticsTag />
                    <CssBaseline/>
                    <div className="App-header">
                        <h2>My SEO Toolbox</h2>
                    </div>
                    <NavBar/>
                    <Container className="big-fat-grid">
                        <ErrorBoundary>
                            <RecommendationEditor/>
                            <AuthenticatedRoute exact path="/" component={WorkspaceSelectionPage}/>
                            <AuthenticatedRoute path="/account/:section?" component={AccountDetails}/>

                            <AuthenticatedRoute exact path="/change-subscription" component={Pricing}/>
                            <Route exact path="/pricing" component={Pricing}/>
                            <Route exact path="/checkout" component={Checkout}/>

                            <Route exact path="/login" component={LoginView}/>
                            <Route exact path="/register" component={SignUpView}/>
                            <Route exact path="/email-verify" component={EmailVerifyView}/>
                            <Route exact path="/forgotten-password" component={ForgottenPassword}/>
                            <Route exact path="/reset-password" component={ResetPassword}/>

                            <Route path="/error" component={SomethingWentWrong}/>
                            <AuthenticatedRoute path="/monitored-uris/:id" component={CurrentMonitoredUri}/>
                            <AuthenticatedRoute path="/ws/:workspaceNumber/add-url" component={AddUrl}/>
                            <AuthenticatedRoute path="/ws/:workspaceNumber/admin" component={WorkspaceAdmin}/>
                            <Route path="/ws/:workspaceNumber" component={SelectionListener}/>
                            <AuthenticatedRoute exact path="/ws/:workspaceNumber/" component={Workspace}/>
                            {/*<Route component={NotFound}/>*/}
                        </ErrorBoundary>
                    </Container>
                    {/*<Footer/>*/}
                </QueryParamProvider>
            </HashRouter>
        )
    }
}


const container = document.getElementById('root');
const root = createRoot(container);


const store = createStore(rootReducer, applyMiddleware(thunk));

root.render(<Provider store={store}>
    <CookiesProvider>
        <App/>
    </CookiesProvider>
</Provider>);