import React, {useRef, useState} from "react";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap";
import {useSelector} from "react-redux";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {generateUrlForWorkspace} from "../Workspace/common";
import {useLocation} from "react-router-dom";
import {CountryFlag} from "../Workspace/CountryFlag";

const WorkspaceSelector = () => {
    const location = useLocation();
    const {workspaces} = useSelector((state) => state);

    const [searchQuery, setSearchQuery] = useState("");

    const searchInputRef = useRef(null);

    const focusSearchInput = () => {
        setTimeout(() => {
            if (searchInputRef.current) {
                searchInputRef.current.focus();
            }
        }, 0);
    };

    if (!workspaces?.fetched) return null;

    const generateUrl = (workspaceNumber) => {
        const curUrl = location.pathname;
        const workspacePattern = /(.*\/ws\/)(\d+)(\/.*)/;

        if (curUrl.match(workspacePattern)) {
            return curUrl.replace(workspacePattern, `$1${workspaceNumber}$3`);
        }

        return generateUrlForWorkspace(workspaceNumber);
    };

    const filterWorkspaces = () => {
        return workspaces.data.filter(
            (workspace) =>
                workspace.name ? workspace.name.toLowerCase().includes(searchQuery.toLowerCase()) : "Unnamed Workspace");
    };

    const clearSearch = () => {
        setSearchQuery("");
        focusSearchInput();
    };

    const renderWorkspaces = () => {
        const filteredWorkspaces = filterWorkspaces();
        return filteredWorkspaces.length > 0 ? (
            filteredWorkspaces.map((workspace) => (
                <DropdownItem key={workspace.seqNumber} className="workspace-item">
                    <div className="workspace-info">
                        {workspace.countryCode && (
                            <CountryFlag
                                countryCode={workspace.countryCode}
                                width={25}
                                className="country-flag"
                            />
                        )}
                        <a
                            href={`/#${generateUrl(workspace.seqNumber)}`}
                            className="workspace-link"
                        >
                            {workspace.name || "Unnamed Workspace"}
                        </a>
                    </div>
                </DropdownItem>
            ))
        ) : (
            <DropdownItem className="workspace-item">
                <span>No workspaces found</span>
            </DropdownItem>
        );
    };

    return (
        <div className="workspace-selector-wrapper">
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="workspace-toggle" onClick={focusSearchInput}>
                    {workspaces.current?.countryCode && (
                        <CountryFlag
                            countryCode={workspaces.current.countryCode}
                            width={25}
                            className="country-flag"
                        />
                    )}


                    {workspaces.current.seqNumber
                            ? workspaces.current.name || "Unnamed workspace"
                            : "Select Workspace"
                    }


                </DropdownToggle>
                <DropdownMenu className="workspace-dropdown-menu">
                    <div className="workspace-search-container">
                        <TextField
                            inputRef={searchInputRef}
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search..."
                            fullWidth
                            InputProps={{
                                endAdornment: searchQuery && (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={clearSearch}>
                                            <ClearIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    {renderWorkspaces()}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};

export default WorkspaceSelector;