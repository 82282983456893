import React from "react";
import Flag from "react-world-flags";
import styled from "styled-components";

const FlagWrapper = styled.div`
    width: ${(props) => props.width}px;
    height: ${(props) => props.width * 0.67}px; 
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 4px; 
`;

const StyledFlag = styled(Flag)`
    width: 100%;
    height: 100%;
    object-fit: cover; 
`;

export const CountryFlag = ({ countryCode, width = 32 }) => {
    if (!countryCode || countryCode.length < 2) return null;

    const normalizedCode = countryCode.toUpperCase() === "UK" || countryCode.toUpperCase() === "EN"
        ? "GB"
        : countryCode.toUpperCase();

    return (
        <FlagWrapper width={width}>
            <StyledFlag code={normalizedCode} />
        </FlagWrapper>
    );
};
