import React, {useCallback} from "react";
import {Col, Row} from "reactstrap";
import styled from "styled-components";
import CardHeader from "@material-ui/core/CardHeader";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {fetchWorkspaceStats} from "../../actions/stats";
import {generateUrlForWorkspace} from "../Workspace/common";
import {DashboardWidgetCard, FlexCardContent} from "./DashboardComponents";
import HttpStatusTable from "./HttpStatusTable";
import {PieChart} from "@mui/x-charts/PieChart";

const ConstHeightCol = styled.div`

    margin-top: auto;
    margin-bottom: auto;
    padding-left: 0.3em;
    padding-right: 0.3em;
    max-width: 50%;

    @media (max-width: 576px) {
        max-width: 90%;
        margin: .5em auto .9em;
    }
}`;

function IndexStatsWidget({match, workspaceStats}) {
    const getWorkspaceNumber = useCallback(() => match.params.workspaceNumber, [match]);

    const linkForStatusCodeFilter = useCallback(
        code => generateUrlForWorkspace(getWorkspaceNumber()) + "?httpStatus=" + code,
        [getWorkspaceNumber]
    );

    if (!workspaceStats.fetched) return null;

    const stats = workspaceStats.statusCodeStats;

    const pieChartData = [
        {id: "HTTP200", value: stats.just200Count, color: "#187d06", label: "HTTP 200"},
        {id: "HTTP301", value: stats.http301Count, color: "#5c8bf2", label: "HTTP 301"},
        {id: "HTTP3xx", value: stats.http3xxCount, color: "#eba100", label: "HTTP 3xx"},
        {id: "HTTP4xx", value: stats.http4xxCount, color: "#fe000b", label: "HTTP 4xx"},
        {id: "HTTP5xx", value: stats.http5xxCount, color: "#fe000b", label: "HTTP 5xx"},
    ];

    return (
        <DashboardWidgetCard>
            <CardHeader title="HTTP Status Code"/>
            <FlexCardContent>
                <Row>
                    <ConstHeightCol sm={6}>
                        <PieChart
                            slotProps={{legend: {hidden: true}}}
                            width={240}
                            height={200}
                            series={[
                                {
                                    data: pieChartData,
                                    highlightScope: {fade: 'global', highlight: 'item'},
                                    faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'},
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 3,
                                    cornerRadius: 5,
                                    cx: 116,
                                },
                            ]}
                        />
                    </ConstHeightCol>
                    <ConstHeightCol sm={6}>
                        <HttpStatusTable stats={stats} linkForStatusCodeFilter={linkForStatusCodeFilter}/>
                    </ConstHeightCol>
                </Row>
            </FlexCardContent>
        </DashboardWidgetCard>
    );
}

const mapStateToProps = (state) => ({
    workspaceStats: state.stats.workspaceStats,
});

export default withRouter(
    connect(mapStateToProps, {fetchWorkspaceStats})(IndexStatsWidget)
);