import React from 'react';
import { Box, Typography, LinearProgress, withStyles, makeStyles } from '@material-ui/core';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 25,
        borderRadius: 5,
        position: 'relative',
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[300],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
    },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
    progressContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
    },
    progressText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#ffffff',
        fontWeight: 'bold',
        zIndex: 1, // Ensure text is above the progress bar
    },
    progressBar: {
        width: '100%',
    },
}));

function Progress({ progress }) {
    const classes = useStyles();

    return (
        <Box className={classes.progressContainer}>
            <Typography variant="body2" className={classes.progressText}>
                {`${Math.round(progress)}%`}
            </Typography>
            <BorderLinearProgress
                variant="determinate"
                value={progress}
                className={classes.progressBar}
            />
        </Box>
    );
}

export default Progress;